import { defineStore } from 'pinia';

export interface Notification {
  id: symbol;
  message: string | any;
  type: 'success' | 'error' | 'info';
}

export interface Notifications {
  notifications: Array<Notification>;
}

const useNotifications = defineStore('notifications', () => {
  const notifications = ref<Array<Notification>>([]);

  function send(data: { message: string | any; type: 'success' | 'error' | 'info' }) {
    let notification = {
      id: Symbol(),
      message: data.message,
      type: data.type,
    };

    notifications.value.push(notification);

    setTimeout(() => {
      notifications.value = notifications.value.filter(() => {
        return notification.id != notification.id;
      });
    }, 5000);
  }

  function remove(id: Symbol) {
    notifications.value = notifications.value.filter((notification) => {
      return notification.id !== id;
    });
  }

  return { notifications, send, remove };
});

export default useNotifications;
